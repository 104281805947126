<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<div>
			<div class="d-flex align-items-center">
				<b-button
					variant="primary"
					@click="$router.push({ name: 'OrdersSettlements' })">
					<span class="fa fa-arrow-left" />
				</b-button>
				<h5
					v-if="!loading"
					class="mb-0 ml-2">
					{{ datePeriod }}
				</h5>
			</div>
			<hr>
			<div
				v-if="!loading"
				class="row mb-2 d-flex text-right">
				<div class="col-md-6 col-lg-3">
					<icon-card-widget
						:big-label="ordersTotal"
						:small-label="translate('orders_total')" />
				</div>
				<div class="col-md-6 col-lg-3">
					<icon-card-widget
						:big-label="otherTransactionsTotal"
						:small-label="translate('other_transactions_total')" />
				</div>
			</div>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th
								class="border-top-0 pointer" />
							<!--<th
								class="border-top-0 pointer">
								{{ translate('posted_date') }}
							</th>-->
							<th
								class="border-top-0 pointer">
								{{ translate('date_time') }}
							</th>
							<th class="border-top-0">
								{{ translate('order_id') }}
							</th>
							<!--<th
								class="border-top-0 pointer">
								{{ translate('merchant_order_id') }}
							</th>
							<th
								v-if="isAnyExpanded"
								class="border-top-0 text-right pointer">
								{{ translate('transaction_type') }}
							</th>-->
							<th
								v-if="isAnyExpanded"
								class="border-top-0 pointer">
								{{ translate('product') }}
							</th>
							<th
								v-if="isAnyExpanded"
								class="border-top-0 pointer">
								{{ translate('qty') }}
							</th>
							<!--<th
								v-if="isAnyExpanded"
								class="border-top-0 pointer">
								{{ translate('order_item_code') }}
							</th>
							<th
								v-if="isAnyExpanded"
								class="border-top-0 pointer">
								{{ translate('shipment_id') }}
							</th>
							<th
								v-if="isAnyExpanded"
								class="border-top-0 pointer">
								{{ translate('marketplace_name') }}
							</th>
							<th
								v-if="isAnyExpanded"
								class="border-top-0 pointer">
								{{ translate('merchant_order_item_id') }}
							</th>
							<th
								v-if="isAnyExpanded"
								class="border-top-0 pointer">
								{{ translate('merchant_adjustment_item_id') }}
							</th>-->
							<th
								v-if="isAnyExpanded"
								class="border-top-0 pointer">
								{{ translate('promotion_id') }}
							</th>
							<th
								class="border-top-0 pointer">
								{{ translate('amount_type') }}
							</th>
							<th
								class="border-top-0 text-right pointer">
								{{ translate('amount_description') }}
							</th>
							<th
								:colspan="isAnyExpanded ? '0' : '12'"
								class="border-top-0 text-right pointer">
								{{ translate('amount') }}
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading && hasData">
						<transition
							v-for="(item, index) in data"
							:key="index"
							name="slide-fade">
							<tr
								v-if="isParent(item) || showChild(item)"
								@click="handleRowClick(item)">
								<td>
									<em
										v-if="!isFiltering && isExpandable(item)"
										:class="`fas fa-chevron-${showChild(item) ? 'up' : 'down'}`" />
								</td>
								<!--<td
									:class="`align-middle ${customClasses(item)}`">
									{{ item.attributes.posted_date }}
								</td>-->
								<td
									:class="`align-middle ${customClasses(item)}`">
									{{ item.attributes.posted_date_time }}
								</td>
								<td
									:class="`align-middle font-weight-bold' ${customClasses(item)}`">
									{{ item.attributes.order_id }}
								</td>
								<!--<td
									:class="`align-middle font-weight-bold' ${customClasses(item)}`">
									{{ item.attributes.merchant_order_id }}
								</td>
								<td
									:class="`align-middle text-right font-weight-bold ${customClasses(item)}`">
									{{ item.attributes.transaction_type }}
								</td>-->
								<td
									v-if="isAnyExpanded"
									:class="`align-middle font-weight-bold ${customClasses(item)}`">
									{{ translate(item.attributes.code_name) }}
								</td>
								<td
									v-if="isAnyExpanded"
									:class="`align-middle text-center ${customClasses(item)}`">
									{{ item.attributes.quantity_purchased }}
								</td>
								<!--<td
									:class="`align-middle ${customClasses(item)}`">
									{{ item.attributes.order_item_code }}
								</td>
								<td
									:class="`align-middle ${customClasses(item)}`">
									{{ item.attributes.shipment_id }}
								</td>
								<td
									:class="`align-middle ${customClasses(item)}`">
									{{ item.attributes.marketplace_name }}
								</td>
								<td
									:class="`align-middle ${customClasses(item)}`">
									{{ item.attributes.merchant_order_item_id }}
								</td>
								<td
									:class="`align-middle ${customClasses(item)}`">
									{{ item.attributes.merchant_adjustment_item_id }}
								</td>-->
								<td
									v-if="isAnyExpanded"
									:class="`align-middle text-center ${customClasses(item)}`">
									<a
										v-if="item.attributes.promotion_id"
										v-b-tooltip.hover
										:href="infoLink"
										target="_blank"
										:title="item.attributes.promotion_id"
										class="text-decoration-none link-primary mr-2">
										<i class="fas fa-info-circle" />
									</a>
								</td>
								<td
									:class="`align-middle ${customClasses(item)}`">
									{{ item.attributes.amount_type }}
								</td>
								<td
									:class="`align-middle text-right ${customClasses(item)}`">
									{{ item.attributes.amount_description }}
								</td>
								<td :class="`align-middle text-right ${customClasses(item)}`">
									{{ item.attributes.amount }}
								</td>
							</tr>
						</transition>
						<tr>
							<td
								:colspan="isAnyExpanded ? '8' : '5'"
								class="align-middle text-right font-weight-bold">
								{{ translate('total') }}
							</td>
							<td class="align-middle text-right font-weight-bold">
								{{ settlementTotal }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="loading"
				:has-data="hasData" />
		</div>
	</div>
</template>
<script>
import {
	Grids, Settlement as SettlementMessages, Products,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import IconCardWidget from '@/components/IconCardWidget';
import { PAGINATION } from '@/settings/RequestReply';
import Order from '@/util/Order';
import { MDY_FORMAT } from '@/settings/Dates';

export default {
	name: 'SettlementDetail',
	messages: [Grids, Products, SettlementMessages],
	components: {
		IconCardWidget,
	},
	mixins: [FiltersParams],
	data() {
		return {
			order: new Order(),
			currentExpandIds: [],
			dateFormat: MDY_FORMAT,
			rangeStart: '',
			rangeEnd: '',
		};
	},
	computed: {
		loading() {
			return !!this.order.data.loading;
		},
		data() {
			try {
				const { data } = this.order.data.response.data;
				// The following code is to show the name of the product only once for each order.
				// The quantity_purchased is only shown if the name of the product is also shown or if the amount description is "Principal".
				let childIndex = 0;
				let childCodename = '';
				return data.map((item) => {
					if (item.attributes.is_parent) {
						childIndex = 0;
						childCodename = '';
					} else {
						childIndex += 1;

						if (item.attributes.code_name !== childCodename) {
							childIndex = 0;
						}

						if (childIndex > 0) {
							item.attributes.code_name = '';
							if (item.attributes.amount_description !== 'Principal') {
								item.attributes.quantity_purchased = '';
							}
						} else {
							childCodename = item.attributes.code_name;
						}
					}

					return item;
				});
			} catch (error) {
				return [];
			}
		},
		totals() {
			try {
				const { totals } = this.order.data.response.data.meta;
				return totals;
			} catch (error) {
				return [];
			}
		},
		ordersTotal() {
			try {
				const { meta } = this.order.data.response.data;
				return meta.orders_total;
			} catch (error) {
				return [];
			}
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		otherTransactionsTotal() {
			try {
				const { meta } = this.order.data.response.data;
				return meta.other_transactions_total;
			} catch (error) {
				return [];
			}
		},
		settlementTotal() {
			try {
				const { meta } = this.order.data.response.data;
				return meta.total;
			} catch (error) {
				return '';
			}
		},
		hasData() {
			if (this.data) {
				const response = this.data.length;
				return !!response;
			}
			return false;
		},
		isFiltering() {
			return Object.keys(this.$route.params).some((item) => ['dateRange'].includes(item));
		},
		isAnyExpanded() {
			return this.currentExpandIds.length > 0;
		},
	},
	created() {
		this.getDetails();
	},
	methods: {
		getDetails() {
			this.order.getSettlementDetails(this.$route.params.settlement_id).then(() => {
				this.rangeStart = this.$moment(this.order.data.response.data.meta.start_date.date).format(this.dateFormat);
				this.rangeEnd = this.$moment(this.order.data.response.data.meta.end_date.date).format(this.dateFormat);
			});
		},
		handleRowClick(item) {
			if (!this.isExpandable(item)) return;
			if (this.currentExpandIds.includes(Number(item.id))) {
				this.currentExpandIds = this.currentExpandIds.filter((id) => id !== Number(item.id));
			} else {
				this.currentExpandIds.push(Number(item.id));
			}
		},
		isExpandable(item) {
			return this.isParent(item) && this.isOrder(item) && this.hasChildren(item);
		},
		isParent(item) {
			return item.attributes.is_parent || this.isFiltering;
		},
		showChild(item) {
			return this.currentExpandIds.includes(Number(item.id));
		},
		hasChildren(item) {
			return item.attributes.has_children;
		},
		isOrder(item) {
			return item.attributes.order_id !== null;
		},
		customClasses(item) {
			if (this.isFiltering) {
				return '';
			}
			if (this.isExpandable(item)) {
				return 'pointer';
			}
			return 'text-medium';
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.pointsSummaries.getPointsSummaries(options);
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
